import { Component, OnInit, OnDestroy } from "@angular/core";
import noUiSlider from "nouislider";
@Component({
  selector: "app-politicas",
  templateUrl: "politicas.component.html",
  styleUrls: ["politicas.component.scss"]
})
export class PoliticasComponent implements OnInit, OnDestroy {
  focus;
  focus1;
  focus2;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  constructor() {}
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
    var element = document.getElementById("navigation");
    element.click();

  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }
}
