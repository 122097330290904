import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MarketsComponent } from "./markets/markets.component";

import { IndexComponent } from "./index/index.component";
import { LandingpageComponent } from "./examples/landingpage/landingpage.component";
import { ContactComponent } from "./contact/contact.component";
import { PoliticasComponent } from "./politicas/politicas.component";
import { RetirosComponent } from "./retiros/retiros.component";

const routesPages: Routes = [
  { path: "", component: IndexComponent},
  { path: "markets", component: MarketsComponent},
  { path: "landing", component: LandingpageComponent},
  { path: "contact", component: ContactComponent},
  { path: "privacy", component: PoliticasComponent},
  { path: "withdrawals", component: RetirosComponent},
  
];


@NgModule({
  imports: [
    RouterModule.forChild(routesPages)
  ]
})
export class PagesRoutingModule {}
