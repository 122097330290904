import { Component, OnInit, OnDestroy } from "@angular/core";
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
@Component({
  selector: "app-contact",
  templateUrl: "contact.component.html",
  styleUrls: ["contact.component.scss"]
})
export class ContactComponent implements OnInit, OnDestroy {
  focus;
  focus1;
  focus2;
  mostrar = false;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  constructor() {}
  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
    var element = document.getElementById("navigation");
    element.click();
  }
  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_p41999g', 'template_4x03515', e.target as HTMLFormElement, '5z_7-s2t1c9tqf5lx')
      .then((result: EmailJSResponseStatus) => {
        this.mostrar = true;
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }
}
